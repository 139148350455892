.excel-table-container {
    width: 100%;
    overflow-x: auto;
}

.table-wrapper {
    width: 100%;
    max-height: 150px;
    overflow: auto;
}

/* Fullscreen styles */
.table-wrapper:fullscreen {
    width: 100%;
    height: 100%;
    background-color: white;
    overflow: auto;
}

.table-wrapper:-webkit-full-screen {
    width: 100%;
    height: 100%;
    background-color: white;
    overflow: auto;
}

.table-wrapper:-moz-full-screen {
    width: 100%;
    height: 100%;
    background-color: white;
    overflow: auto;
}

.table-wrapper:-ms-fullscreen {
    width: 100%;
    height: 100%;
    background-color: white;
    overflow: auto;
}

.excel-table {
    width: 100%;
    border-collapse: collapse;
    font-size: 12px;
    table-layout: fixed;
    overflow: auto;
}

.excel-table th,
.excel-table td {
    padding: 8px;
    border: 1px solid #ddd;
}

.excel-table th {
    background-color: #f2f2f2;
    position: sticky;
    top: 0;
    z-index: 1;
}

.excel-table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.excel-table tr:nth-child(odd) {
    background-color: #ffff;
}

.excel-table tr:hover {
    background-color: #ddd;
}

.fullscreen-img {
    position: fixed;
    top: 160px;
    right: 15px;
    width: 15px;
    cursor: pointer;
}

.fullscreen-img:hover {
    background-color: #ddd;
}